jQuery(function($) {

    /* initialize FlexNav */

    $(".flexnav").flexNav({
        'calcItemWidths': false,
        'hover': false
    });

    /* clone overview pages into submenu */

    $('.menu-item-has-children > a').each(function (index, el) {

        if ($(this).parent().hasClass('append-to-sub')) {

            var target = $(this).next('ul');
            var classes = $(this).parent().attr("class");
            var text = $(this).data("title");

            $(this).clone().prependTo(target).wrap('<li></li>').text(text).parent().attr("class", classes).removeClass('menu-item-has-children item-with-ul current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_parent current_page_ancestor append-to-sub');
            $(this).attr("href", "#");
        } else {
            $(this).attr("href", "#");
        }

    });

    /* initialize home slider */

    if ($('#sliderWrapper').length && $('#sliderWrapper').hasClass("owlActive")) {

        $("#sliderWrapper").owlCarousel({
            autoplay: true,
            autoplayTimeout: 14000,
            items: 1,
            loop: true,
            smartSpeed: 400,
            dots: true,
            dotsSpeed: 300,
            autoHeight: true,
            navText: ['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
            responsive: {
                0: {
                    nav: false,
                },
                651: {
                    nav: true,
                },
            }
        });

        $('.owl-item video').each(function () {
            $(this).get(0).play();
        });

    }

    /* initialize page slider */

    if ($('#pageSlider').length && $('#pageSlider .sliderWrapper').hasClass("owlActive")) {

        $("#pageSlider .sliderWrapper").owlCarousel({
            autoplay: true,
            autoplayTimeout: 7000,
            items: 1,
            nav: false,
            loop: false,
            smartSpeed: 400,
            dotsSpeed: 300,
            dotsContainer: '#owlDots',
            navText: ['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
            responsive: {
                0: {
                    dots: false,
                    nav: false
                },
                600: {
                    dots: true,
                },
                651: {
                    nav: true
                }
            }
        });

    }

    /* initialize history slider */

    if ($('.history').length && $('.history').hasClass("owlActive")) {

        var timeLine = $('#timeLineSlider .years');
        var history = $('#historySlider .slides');
        var content = $('#contentSlider .slides');

        $('#timeLineSlider .year').first().addClass('current');

        timeLine.owlCarousel({
            nav: false,
            dots: false,
            pullDrag: false,
            touchDrag: false,
            mouseDrag: false,
            center: true,
            responsive: {
                0: {
                    items: 3,
                },
                700: {
                    items: 5,
                },
            }
        }).on('click', '.owl-item', function (event) {
            history.trigger('to.owl.carousel', [$(event.target).parents('.owl-item').index(), 0, true]);
            if (typeof ga === 'function') {
                ga('send', 'event', 'slider', 'history', $(this).text());
            }
        });

        history.owlCarousel({
            navText: ['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
            navContainer: '#history-nav',
            dots: false,
            navRewind: false,
            margin: 10,
            items: 1,
            smartSpeed: 400,
            navSpeed: 600,
            responsive: {
                0: {
                    nav: false,
                },
                651: {
                    nav: true,
                },
            }
        }).on('changed.owl.carousel', function (event) {
            timeLine.trigger('to.owl.carousel', [event.item.index, 500, true]);
            timeLine.find('.current').removeClass('current');
            timeLine.find('.owl-item .year').eq(event.item.index).addClass('current');
            content.trigger('to.owl.carousel', [event.item.index, 700, true]);
            if (typeof ga === 'function') {
                ga('send', 'event', 'slider', 'history', 'slide' + event.item.index);
            }
        });

        content.owlCarousel({
            nav: false,
            dots: false,
            margin: 10,
            items: 1,
            autoHeight: true,
            pullDrag: false,
            touchDrag: false,
            mouseDrag: false,
        });

    }

    /* download form */

    if ($('.fileList').length) {

        $('#list').val($('.fileList').attr('data-id'));

        /* bei der Auswahl einer Broschüre die gewählten Broschüren in ein verstecktes Feld schreiben */

        $('.fileItem').on('click', function () {
            $(this).toggleClass('active');
            $('#downloads, #titles').val('');
            $('.fileItem').each(function () {
                if ($(this).hasClass('active')) {
                    var id = $(this).attr('data-id');
                    $('#downloads').val($('#downloads').val() + id + ',');
                    var title = $(this).attr('data-title');
                    $('#titles').val($('#titles').val() + title + ',');
                }
            });
        });

        /* Nachdem das Formular abgesendet wurde, google Events für die gewählten Broschüren raussenden */

        document.addEventListener('wpcf7submit', function (event) {
            var inputs = event.detail.inputs;
            for (var i = 0; i < inputs.length; i++) {
                if ('titles' == inputs[i].name) {
                    var titlesArray = inputs[i].value.split(',')
                    for (var i = 0; i < titlesArray.length; i++) {
                        if (titlesArray[i]) {
                            if (typeof ga === 'function') {
                                ga('send', 'event', 'form', 'all-downloads', titlesArray[i]);
                            }
                        }
                    }
                    break;
                }
            }

        }, false);

    }

    /* recruiting form */

    $('input[type=file]').on('change', function () {
        var filename = $(this).val().split('\\').pop();
        if (filename)
            $(this).parent().parent().find('label').html(filename).addClass('selected');
        else
            $(this).parent().parent().find('label').html('').removeClass('selected');
    });

    /*
     * timeout function
     */

    function createFunctionWithTimeout(callback, opt_timeout) {
        var called = false;

        function fn() {
            if (!called) {
                called = true;
                callback();
            }
        }

        setTimeout(fn, opt_timeout);
        return fn;
    }

    /* hotspotsElement */

    enquire.register("screen and (min-width: 750px)", {
        match: function () {

            $('.hotspotsElement .box').addClass('hide');

            $(document).on('click touchstart', function (event) {
                if (!$(event.target).closest('.box').length) {
                    if ($('.hotspotsElement').hasClass("active")) {
                        $('.hotspotsElement').removeClass('active');
                        $('.hotspotsElement .box').addClass('hide');
                    }
                }
            });

            $('.hotspotsElement .box').on('click', function (e) {
                event.stopPropagation();
            });

            $('.hotspotsElement .number').on('click', function (e) {
                $('.hotspotsElement').addClass('active');
                $(this).closest('.hotspotsElement').find('.box:eq(' + $(this).index() + ')').removeClass('hide');
                e.stopPropagation();
            });

            $('.hotspotsElement .close').on('click', function (e) {
                $('.hotspotsElement').removeClass('active');
                $('.hotspotsElement .box').addClass('hide');
                e.stopPropagation();
            });
        },

        unmatch: function () {
            $('.hotspotsElement .box').removeClass('hide');
            $('.hotspotsElement .number, .hotspotsElement .box, .hotspotsElement .close').off('click');
        }
    });


    /**********************************************
     * FORMS                                      *
     **********************************************/

    /* label animation */

    $(".fieldWrapper input[type='text'], .fieldWrapper input[type='email'], .fieldWrapper textarea").on('blur', function () {
        if (!$(this).val()) {
            $(this).parent().parent().removeClass('active');
        }
    }).on('focus', function () {
        $(this).parent().parent().addClass('active');
    });

    /* enable select2 for select fields */

    $('.selectTwo').select2({
        minimumResultsForSearch: Infinity,
        width: "style"
    });

    /* search Form */

    $('.searchButton').on('click', function () {
        $(".searchForm").toggleClass('hide');
        if (typeof ga === 'function' && !$(".searchForm").hasClass('hide')) {
            ga('send', 'event', 'search', 'opened', 'click');
        }
        if (!$(".languages").hasClass('hide')) {
            $(".languages").toggleClass('hide');
        }
    });

    /* search Phrase */

    if ($('.searchResults').length) {
        if (typeof ga === 'function') {
            ga('send', 'event', 'search', 'searched', $('.searchResults').attr('data-searchphrase'));
        }
    }

    /* langSwitch */

    $('.langButton').on('click', function () {
        $(".languages").toggleClass('hide');
        if (typeof ga === 'function' && !$(".languages").hasClass('hide')) {
            ga('send', 'event', 'langSwitch', 'opened');
        }
        if (!$(".searchForm").hasClass('hide')) {
            $(".searchForm").toggleClass('hide');
        }
    });

    /* boxForm */

    $('.upper button').on('click', function () {
        $(this).parent().parent().next('.lower').slideToggle(400);
    });

    /* boxForm Produktbroschüre */

    if ($('#downloadForm').length) {

        $('#fileId').val($('#downloadForm').attr('data-id'));
        $('#title').val($('#downloadForm').attr('data-title'));

        /* Nachdem das Formular abgesendet wurde, google Events für die gewählten Broschüren raussenden */

        document.addEventListener('wpcf7submit', function (event) {
            var inputs = event.detail.inputs;
            for (var i = 0; i < inputs.length; i++) {
                if ('title' == inputs[i].name) {
                    if (inputs[i].value) {
                        if (typeof ga === 'function') {
                            ga('send', 'event', 'form', 'download', inputs[i].value);
                        }
                    }
                    break;
                }
            }

        }, false);

    }

    /* service */

    $('#service a').on("click", function (e) {

        e.preventDefault();
        var title = $(this).attr('data-tooltip');
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'nav', 'service', title, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* slider */

    $('#slider a.slide').on("click", function (e) {

        e.preventDefault();
        var title = $(this).find('.title').text();
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'slider', 'home', title, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* branches slider */

    $('#pageSlider a.slide').on("click", function (e) {

        e.preventDefault();
        var title = $(this).find('.sliderText').text();
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'slider', 'branches', title, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* feature boxes */

    $('a.feature').on("click", function (e) {

        e.preventDefault();
        var title = $(this).find('.featureText').text();
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'boxes', 'feature', title, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* animated map */

    $('.animatedMap a').on("click", function (e) {

        e.preventDefault();
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'location', 'feature', 'click', {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* job box */

    $('.box.job:not(.azb) a').on("click", function (e) {

        e.preventDefault();
        var title = $(this).attr('data-jobname');
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'boxes', 'job', title, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* apprenticeship box */

    $('.box.job.azb a').on("click", function (e) {

        e.preventDefault();
        var title = $(this).attr('data-jobname');
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'boxes', 'apprenticeship', title, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* boxes link */

    $('.boxes .link').on("click", function (e) {

        e.preventDefault();
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'boxes', 'jobs', 'showMore', {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* boxes link */

    $('.boxes a.download').on("click", function (e) {

        e.preventDefault();
        var url = $(this).attr('href');
        var file = $(this).attr('data-filename');

        if (typeof ga === 'function') {
            ga('send', 'event', 'boxes', 'download', file, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* calendar ics link */

    $('a.ics').on("click", function (e) {

        e.preventDefault();
        var url = $(this).attr('href');
        var title = $(this).attr('data-fairname');

        if (typeof ga === 'function') {
            ga('send', 'event', 'boxes', 'fair', title, {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });


    /* mailto link */

    $('footer a[href^="mailto:"]').on("click", function (e) {

        e.preventDefault();
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'footer', 'contact', 'click', {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* appstore link */

    $('footer .more a').on("click", function (e) {

        e.preventDefault();
        var url = $(this).attr('href');

        if (typeof ga === 'function') {
            ga('send', 'event', 'footer', 'appstore', 'click', {
                'hitCallback': createFunctionWithTimeout(function () {
                    document.location = url
                }, 500)
            });
        } else {
            document.location = url;
        }

    });

    /* Formulare */

    document.addEventListener('wpcf7mailsent', function (event) {
        if (typeof ga === 'function') {
            ga('send', 'event', 'form', 'submit');
        }
    }, false);

    /* list element */

    $('.list h2').each(function (i) {
        var number = i + 1;
        $(this).nextUntil('h2').andSelf().wrapAll('<div class="item" data-number="' + number + '."></div>');
    });

    /* divider element */

    $("div.divider:odd").addClass('second');

    function doneResizing() {
        windowWidth = $(window).width();
        // do something after resizing
    }

    function doneScrolling() {
        windowScrollTop = $(window).scrollTop();
        // do something after scroll
        toTopButton();
    }

    function toTopButton() {
        if (windowScrollTop > 100 && windowWidth > 1024) {
            $('.scrollTop').fadeIn();
        } else {
            $('.scrollTop').fadeOut();
        }
    }

    // save window.width in var after Page load
    var windowWidth = $(window).width();
    // save window.scrollTop in var after Page load
    var windowScrollTop = $(window).scrollTop();

    // update window.width and window.scrollTop after resizing or scrolling
    setInterval(function () {
        if (windowWidth != $(window).width())
            doneResizing();
        if (windowScrollTop != $(window).scrollTop())
            doneScrolling();
    }, $(window).width() > 768 ? 500 : 2500);

    $('footer').append("<span class='scrollTop'></span>");

    $('.scrollTop').on('click', function () {
        $("html, body").animate({scrollTop: 0}, 'slow', 'swing');
        return false;
    });

    /*
    *  new_map
    *
    *  This function will render a Google Map onto the selected jQuery element
    *
    *  @type  function
    *  @date  8/11/2013
    *  @since 4.3.0
    *
    *  @param $el (jQuery element)
    *  @return  n/a
    */

    function new_map($el) {

        // var
        var $markers = $el.find('.marker');

        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        };

        // create map
        var map = new google.maps.Map($el[0], args);

        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function () {

            add_marker($(this), map);

        });

        // center map
        center_map(map);

        // return
        return map;

    }

    function add_marker($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });

        // add to array
        map.markers.push(marker);

    }

    function center_map(map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(17);
        } else {
            // fit to bounds
            /*map.fitBounds( bounds );*/
            map.setCenter(bounds.getCenter());
            map.setZoom(6);
        }

    }

    function resize_map(map) {

        google.maps.event.trigger(map, 'resize');
        center_map(map);

    }

    /* location tabs */

    (function ($) {
        $.fn.createMap = function () {
            if ($(this).find('.acf-map').length) {
                if (!$(this).find('.gm-style').length) {
                    var map = new_map($(this).find('.acf-map'));
                    // save into a data attribute
                    $(this).data('map', map);
                } else {
                    // get from data attribute
                    resize_map($(this).data("map"));
                }
            }

            return $(this);

        };

        if ($('.locations').length) {

            enquire.register("screen and (min-width: 650px)", {
    
                match: function () {
    
                    $('.tab').first().addClass('active');
                    $('.location').addClass('hidden').first().removeClass('hidden').createMap();
    
                    $('.tab').on('click', function () {
    
                        var id = $(this).attr('data-id');
                        var title = $(this).text();
    
                        $('.tab').removeClass('active');
                        $(this).addClass('active');
    
                        $('.location').addClass('hidden');
                        $('.location[data-id="' + id + '"]').removeClass('hidden').createMap();
    
                        if (typeof ga === 'function') {
                            ga('send', 'event', 'location', 'country', title);
                        }
    
                    });
    
                },
                unmatch: function () {
    
                    // show all locations
                    $('.location').removeClass('hidden')
    
                    // remove click function
                    $('.tab').off('click');
    
                }
    
            });
    
            enquire.register("screen and (max-width: 649px)", {
    
                match: function () {
    
                    $('.tabs').hide();
                    $('.location').each(function (i) {
                        $(this).createMap();
                    });
    
                },
                unmatch: function () {
    
                    $('.tabs').show();
    
                }
    
            });
    
        }
    })(jQuery);

    /* person */

    $('.personElement').on('click', 'button', function (e) {
        var element = $(e.delegateTarget);
        var person = $(this).closest('.person');
        var persons = $('.person.open');

        if (!element.hasClass('active')) {
            element.addClass('active');
            person.addClass('open').find('.description').slideDown();
        } else if (person.hasClass('open')) {
            element.removeClass('active');
            person.removeClass('open').find('.description').slideUp();
        } else {
            persons.removeClass('open').find('.description').slideUp();
            person.addClass('open').find('.description').slideDown();
        }
    });

});


    /* ticker */

    (function(){
        'use strict';
        var duration = 10000;
        function init(){
            if (document.getElementById('ticker-container')) {
                new InfiniteScroll(document.getElementById('ticker-container'),duration);
            }
        }

        class InfiniteScroll{
            constructor(container,duration){
                this.container = container;
                this.wrapper = this.container.querySelectorAll('.ticker-wrapper')[0];
                // set the duration based on 60fps
                this.duration = 60 * (duration / 1000);
                this.increment = 0;
                this.getWidth = this.getWidth.bind(this);
                this.startScroll = this.startScroll.bind(this);
                this.getWidth();
                window.addEventListener('resize',this.getWidth);
                this.startScroll();
            }

            // get container width
            getWidth(){
                this.start = false;
                this.block = this.wrapper.querySelectorAll('.items-block');
                this.step = Math.floor(this.block[0].clientWidth);
                this.width = Math.ceil(this.container.clientWidth / this.block[0].clientWidth) + 1;
                this.setBlocks();
            }

            // dynamic blocks duplicator
            setBlocks(){
                if(this.block.length < this.width){
                    for(var i =0; i < (this.width - this.block.length); i++){
                        this.cloneBlock();
                    }
                }else{
                    for(var i =0; i < (this.block.length - this.width); i++){
                        this.removeBlock();
                    }
                }
                this.start = true;
            }

            // remove blocks
            removeBlock(){
                this.wrapper.querySelectorAll('.items-block')[0].remove();
            }

            // add blocks
            cloneBlock(){
                var clone = this.block[0].cloneNode(true);
                this.wrapper.appendChild(clone);
            }

            // Scrolling
            startScroll(){
                this.increment += this.step / this.duration;
                if(parseInt(this.increment) >= this.step){
                    this.removeBlock();
                    this.cloneBlock();
                    this.increment = 0;
                    this.wrapper.style.transform = 'translateX(0px)';
                }
                else{
                    this.wrapper.style.transform = 'translateX('+ -parseInt(this.increment) +'px)';
                }
                if(this.start){
                window.requestAnimationFrame(this.startScroll);
            }
            else{
                window.cancelAnimationFrame(this.startScroll);
            }
        }
      }
      document.addEventListener("DOMContentLoaded", function(event) {
        init();
      });
    })();

