jQuery(function($) {

  /* count element */

  function countEqual($this){

    // Enter num from and to
    $({countNum: 0}).animate({countNum: $this.data('count')}, {
      duration: 3000,
      easing:'linear',
      step: function() {
        // What todo on every count
        $this.html(parseInt(this.countNum));
      },
      complete: function() {
        // What todo at the end
        $this.html($this.data('count'));
      }
    });

  }

  $(".count span").each(function() {
      $(this).data('count', parseInt($(this).html(), 10));
      $(this).html('0');
  });

  /* animations */

    /* count */

    if ($('.count .items').length) {
      $('.count .item').addClass('hide');
      hunt(document.querySelectorAll('.count .item'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeInUp-active');
          this.classList.add('active');
          $(this).find('span').each(function() {
            countEqual($(this));
          });
        },
        offset: -150
      });
    }

    /* list */

    if ($('.list').length) {
      $('.list .item').addClass('hide');
      hunt(document.querySelectorAll('.list .item'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeInLeft-active');
        },
        offset: -100
      });
    }

    /* attention */

    if ($('.attention').length) {
      $('.attention .text').addClass('hide');
      hunt(document.querySelectorAll('.attention .text'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeInLeft-active');
        },
        offset: -100
      });
    }

    /* feature */

    if ($('.feature').length) {
      $('.feature').addClass('hide');
      hunt(document.querySelectorAll('.feature'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeIn-active');
        },
        offset: -100
      });
    }

    /* iconList */

    if ($('.iconList .entry').length) {
      $('.iconList .entry').addClass('hide');
      hunt(document.querySelectorAll('.iconList .entry'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeIn-active');
        },
        offset: -100
      });
    }

    /* box */

    if ($('.boxes .box').length) {
      $('.boxes .box').addClass('hide');
      hunt(document.querySelectorAll('.boxes .box'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeInUp-active');
        },
        offset: -100
      });
    }

    /* person */

    if ($('.personElement .person').length) {
      $('.personElement .person').addClass('hide');
      $('.personElement .description').hide();
      hunt(document.querySelectorAll('.personElement .person'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeInUp-active');
        },
        offset: -100
      });
    }

    /* divider */

    if ($('.divider').length) {
      $('.divider').addClass('hide');
      hunt(document.querySelectorAll('.divider'), {
        in: function() {
          this.classList.remove('hide');
          this.classList.add('fadeIn-active');
        },
        offset: -100
      });
    }

    /* hotspots element */

    if ($('.hotspotsElement').length) {
      hunt(document.querySelectorAll('.hotspotsElement'), {
        in: function() {
          this.classList.add('visible');
        }
      });
    }

});
